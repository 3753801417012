import { useState } from "react";

export default function Signup() {
  const [formData, setFormData] = useState({
    email: "",
  });

  const mailData = {
    list: "https://innovelo.us1.list-manage.com/subscribe/post",
    u: "805e834246003d35d0398947f",
    id: "9cd9c117f3",
    spam: "b_805e834246003d35d0398947f_9cd9c117f3",
  };

  return (
    <div className="d-flex flex-column text-center" id="signup">
      <form
        className="mt-3 mx-auto"
        action={mailData.list}
        method="POST"
        noValidate
      >
        <input type="hidden" name="u" value={mailData.u} />
        <input type="hidden" name="id" value={mailData.id} />
        <div className="row mb-2">
          <div className="col">
            <div className="form-floating">
              <input
                className="form-control bg-transparent"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                value={formData.email}
                placeholder="Email address"
                type="email"
                name="EMAIL"
                id="MERGE0"
              />
              <label htmlFor="email">Email address</label>
            </div>
          </div>
        </div>

        <input
          type="submit"
          value="Sign up for updates"
          className="btn btn-transparent border px-5 mb-5"
        />

        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
          aria-label="Please leave the following field empty"
        >
          <label htmlFor={mailData.spam}>Name:</label>
          <input
            type="text"
            name={mailData.spam}
            tabIndex="-1"
            defaultValue=""
          />
        </div>
      </form>
    </div>
  );
}

import logo from "./assets/logo.png";
import Footer from "./components/Footer";
import Signup from "./components/Signup";
export default function App() {
  return (
    <div className="container-fluid d-flex flex-column align-items-center min-vh-100">
      <h1 className="display-1 mt-5">Innovelo</h1>
      <h5 className="display-6">Automotive Innovation</h5>
      <img src={logo} alt="logo" className="contain w-50" />
      <Signup />
      <Footer />
    </div>
  );
}
